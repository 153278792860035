<template>
    <div class="modal" v-if="modelValue" :class="{ active : modelValue }" @click.self="closeModal()">
        <div class="modal_container">
            <div class="modal_header border">
                <div class="modal_info">
                    <h1>Gender</h1>
                </div>
                <button class="close_btn left_out" @click="closeModal()"><i class="fas fa-long-arrow-alt-right"></i></button>
            </div>
            <div class="modal_body">
                <div class="setting_wpr">
                    <Form @submit="handleChangeGender" v-slot="{ errors }">
                        <div class="form_grp">
                            <div class="group_item">
                                <label class="input_label">Gender</label>
                                <div class="field_wpr" :class="{ 'has-error': errors.gender }">
                                    <Field autocomplete="off" name="gender" v-model="form.gender" rules="required">
                                        <multiselect track-by="value" v-model="form.gender" v-bind="gender"></multiselect>
                                    </Field>
                                </div>
                                <ErrorMessage name="gender" class="text-danger" />
                            </div>
                        </div>
                        <div v-show="form.gender">
                            <schedule-component :schedule-data="form" :close-modal="closeModal" title="gender change" />
                        </div>
                        <div class="action_wpr mt-5">
                            <button :disabled="bulkActionLoader" type="button" class="btn cancel_btn" @click="closeModal()">Cancel</button>
                            <button :disabled="bulkActionLoader" class="btn save_btn"><i class="fa fa-spinner fa-spin" v-if="bulkActionLoader"></i>{{ bulkActionLoader ? ' Changing' : 'Change' }}</button>
                        </div>
                    </Form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { defineAsyncComponent } from 'vue'
    import { Field, Form, ErrorMessage } from 'vee-validate'
    import { mapState, mapActions } from 'vuex'

    const ScheduleComponent = defineAsyncComponent(() => import('@/components/Schedule'))

    export default {
        name: 'ChangeGender',

        data () {
            return {
                gender: {
                    mode: 'single',
                    value: [],
                    options: [
                        { value: 'male', label: 'Male' },
                        { value: 'female', label: 'Female' },
                        { value: 'unassigned', label: 'Unassigned' },
                    ],
                    createTag: true
                },

                form: {
                    gender: '',
                    contacts: [],
                    action: '/contacts/update-contacts-gender',
                },
            }
        },

        props: {
            modelValue: Boolean,
            contacts: [Object, Array],
        },

        emits: ['update:modelValue'],

        components: {
            ScheduleComponent,
            Form,
            Field,
            ErrorMessage,
        },

        watch: {
            contacts (contacts) {
                const vm = this;

                vm.form.contacts = vm.contacts;
            },
            modelValue(val) {
                if (val == true) {
                    document.body.classList.add('modal-open');
                } else {
                    document.body.classList.remove('modal-open');
                }
            },
        },

        computed: mapState({
            bulkActionLoader: state => state.contactModule.bulkActionLoader,
        }),

        mounted () {
            const vm = this;

            vm.form.contacts = vm.contacts;
        },

        methods: {
            ...mapActions({
                changeGender: 'contactModule/changeGender',
            }),

            closeModal () {
                const vm = this;

                vm.form.gender = '';
                vm.$emit('update:modelValue', false);
            },

            handleChangeGender (params, { setFieldError }) {
                const vm = this;
                vm.form.setFieldError = setFieldError;
                vm.form.closeModal = vm.closeModal;

                vm.changeGender(vm.form);
            },
        },
    }
</script>
